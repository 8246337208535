import React, { useEffect, useState } from "react";
import './Login.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos de AOS

const CargarEmpleo = () => {
  const [empresa, setEmpresa] = useState('');
  const [puesto, setPuesto] = useState('');
  const [categoria, setCategoria] = useState('');
  const [imagen, setImagen] = useState(null);
  const [preview, setPreview] = useState(null);
  const [anotaciones, setAnotaciones] = useState('');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagen(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGuardar = () => {
    alert('Empleo guardado exitosamente!');
  };

  return (
    <div className="cargar-empleo" style={{ padding: '20px', maxWidth: '500px', margin: '0 auto', background: 'linear-gradient(135deg, #1a237e, #5868d1)', borderRadius: '10px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }} data-aos="fade-down">
        <img 
          src="/CARGAR.png" 
          alt="Admin Logo" 
          style={{ width: '200px', height: '200px' }} 
        />
      </div>
      <h2 style={{ color: 'white', textAlign: 'center' }}>Cargar Empleo</h2>
      
      <div className="form-group">
        <label style={{ color: 'white' }}>Nombre de la Empresa</label>
        <input 
          type="text" 
          className="form-control" 
          value={empresa} 
          onChange={(e) => setEmpresa(e.target.value)} 
          placeholder="Nombre de la Empresa"
        />
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Puesto</label>
        <input 
          type="text" 
          className="form-control" 
          value={puesto} 
          onChange={(e) => setPuesto(e.target.value)} 
          placeholder="Puesto"
        />
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Categorías</label>
        <select 
          className="form-control" 
          value={categoria} 
          onChange={(e) => setCategoria(e.target.value)}
        >
          <option value="">Seleccione una categoría</option>
          <option value="desarrollo">Desarrollo de Software</option>
          <option value="analista">Analista Funcional</option>
          <option value="tecnico">Técnico en Infraestructura</option>
        </select>
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Cargar Imagen</label>
        <input 
          type="file" 
          className="form-control-file" 
          onChange={handleImageChange} 
        />
        {preview && (
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <img 
              src={preview} 
              alt="Vista Previa" 
              style={{ width: '100px', height: '100px', objectFit: 'cover', border: '1px solid #ccc' }} 
            />
          </div>
        )}
      </div>

      <div className="form-group">
        <label style={{ color: 'white' }}>Anotaciones</label>
        <textarea 
          className="form-control" 
          value={anotaciones} 
          onChange={(e) => setAnotaciones(e.target.value)} 
          placeholder="Anotaciones"
        />
      </div>

      <button className="btn btn-primary btn-block" onClick={handleGuardar}>
        Guardar
      </button>
    </div>
  );
}

export default CargarEmpleo;
