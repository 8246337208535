import React from 'react';
import { BrowserRouter as Router, Route, Routes, useHistory } from 'react-router-dom';
import Login from './login';
import Administrador from './administrador';
import Usuario from './usuario';
import CargarEmpleo from './Cempleo';
import Registro from './Registro';
import Inicio from './Inicio';
import MisPostulaciones from './postulaciones';
function App() {
  return (
    <Router>
      <div className="app" >
        <header className="App-header">
        </header>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/admin" element={<Administrador />} />
          <Route path="/usuario" element={<Usuario />} />
          <Route path="/CargarEmpleo" element={<CargarEmpleo/>} />
          <Route path="/Registro" element={<Registro/>} />
          <Route path="/Inicio" element={<Inicio/>} />
          <Route path="/postulaciones" element={<MisPostulaciones/>} />





        </Routes>
      </div>
    </Router>
  );
}

export default App;
