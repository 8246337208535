import React, { useEffect, useState } from 'react';
import './MostrarEmpleos.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaCheck, FaPaperPlane } from 'react-icons/fa'; 
import { useNavigate } from 'react-router-dom'; 
// DESARROLLADO POR LUCHOR._
const MostrarEmpleos = () => {
  const [empleos, setEmpleos] = useState([
    {
      empresa: 'Tech Solutions',
      puesto: 'Desarrollador Full Stack',
      categoria: 'Desarrollo de Software',
      descripcion: 'Buscamos un desarrollador con experiencia en React y Node.js para unirse a nuestro equipo.',
      imagen: '/nx.png', // Imagen modificada
      fecha: '2024-08-24',
      postulado: false
    },
    {
      empresa: 'Consultores IT',
      puesto: 'Analista Funcional',
      categoria: 'Analista Funcional',
      descripcion: 'Se requiere un analista con habilidades en gestión de proyectos y análisis de requisitos.',
      imagen: '/nx.png', // Imagen modificada
      fecha: '2024-08-22',
      postulado: false
    },
    {
      empresa: 'NetSys',
      puesto: 'Técnico en Infraestructura',
      categoria: 'Técnico en Infraestructura',
      descripcion: 'Responsable de la instalación y mantenimiento de redes y servidores.',
      imagen: '/nx.png', // Imagen modificada
      fecha: '2024-08-20',
      postulado: false
    },
    {
      empresa: 'InnovaTech',
      puesto: 'Ingeniero de Software',
      categoria: 'Desarrollo de Software',
      descripcion: 'Buscamos ingenieros apasionados por las tecnologías emergentes para liderar proyectos innovadores.',
      imagen: '/nx.png', // Imagen modificada
      fecha: '2024-08-18',
      postulado: false
    },
  ]);

  const [empleosPostulados, setEmpleosPostulados] = useState([]);
  const [categoriaFiltro, setCategoriaFiltro] = useState('');
  const [fechaFiltro, setFechaFiltro] = useState('');

  const navigate = useNavigate(); 

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const empleosFiltrados = empleos.filter(empleo => {
    const cumpleCategoria = categoriaFiltro ? empleo.categoria === categoriaFiltro : true;
    const cumpleFecha = fechaFiltro ? empleo.fecha >= fechaFiltro : true;
    return cumpleCategoria && cumpleFecha;
  });

  const manejarPostulacion = (index) => {
    const nuevosEmpleos = [...empleos];
    nuevosEmpleos[index].postulado = true;
    setEmpleos(nuevosEmpleos);
    setEmpleosPostulados([...empleosPostulados, nuevosEmpleos[index]]);
  };

  const verPostulaciones = () => {
    navigate('/postulaciones', { state: { empleosPostulados } });
  };

  return (
    <div className="mostrar-empleos" style={{ boxShadow: "0px 4px 30px rgba(0, 0, 0, 20)" }}>
      <br />
      <img 
        src='/lo1.gif' 
        alt="Logo"
        style={{ width: '1100px', height: '400px', borderRadius: '10px', display: 'block', margin: '0 auto', marginBottom: '20px' }}
      />
      <div className="filtros" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div>
          <label style={{ color: 'white' }}>Filtrar por Categoría:</label>
          <select
            className="form-control"
            value={categoriaFiltro}
            onChange={(e) => setCategoriaFiltro(e.target.value)}
            style={{ marginLeft: '10px' }}
          >
            <option value="">Todas</option>
            <option value="Desarrollo de Software">Desarrollo de Software</option>
            <option value="Analista Funcional">Analista Funcional</option>
            <option value="Técnico en Infraestructura">Técnico en Infraestructura</option>
          </select>
        </div>
        <div>
          <label style={{ color: 'white' }}>Filtrar por Fecha de Publicación:</label>
          <input
            type="date"
            className="form-control"
            value={fechaFiltro}
            onChange={(e) => setFechaFiltro(e.target.value)}
            style={{ marginLeft: '10px' }}
          />
        </div>
      </div>
      {empleosFiltrados.map((empleo, index) => (
        <div key={index} className="empleo-card" data-aos="fade-up">
          <div className="empleo-header">
            <img src={empleo.imagen} alt="Logo de la Empresa" className="empleo-logo" />
            <div className="empleo-info">
              <h3>{empleo.puesto}</h3>
              <p>{empleo.empresa}</p>
              <span className="categoria">{empleo.categoria}</span>
            </div>
          </div>
          <div className="empleo-body">
            <p>{empleo.descripcion}</p>
            <small>Publicado el: {empleo.fecha}</small>
          </div>
          <div className="empleo-footer">
            <button 
              className="btn-postularme"
              onClick={() => manejarPostulacion(index)}
              disabled={empleo.postulado}
            >
              {empleo.postulado ? (
                <><FaCheck /> Postulado</>
              ) : (
                <><FaPaperPlane /> Postularme</>
              )}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MostrarEmpleos;
