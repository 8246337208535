import React from 'react';
import './MostrarEmpleos.css'; // Reutilizamos los mismos estilos para consistencia
import { FaCheck } from 'react-icons/fa'; // Icono para mostrar estado de postulación

const MisPostulaciones = ({ empleosPostulados }) => {
  return (
    <div className="mostrar-empleos" style={{ padding: '20px', maxWidth: '1000px', margin: '0 auto' }}>
      <h2 style={{ color: 'white', textAlign: 'center', marginBottom: '20px' }}>Mis Postulaciones</h2>

      {empleosPostulados.length === 0 ? (
        <p style={{ color: 'white', textAlign: 'center' }}>No has postulado a ningún empleo aún.</p>
      ) : (
        empleosPostulados.map((empleo, index) => (
          <div key={index} className="empleo-card" data-aos="fade-up">
            <div className="empleo-header">
              <img src={empleo.imagen} alt="Logo de la Empresa" className="empleo-logo" />
              <div className="empleo-info">
                <h3>{empleo.puesto}</h3>
                <p>{empleo.empresa}</p>
                <span className="categoria">{empleo.categoria}</span>
              </div>
            </div>
            <div className="empleo-body">
              <p>{empleo.descripcion}</p>
              <small>Publicado el: {empleo.fecha}</small>
            </div>
            <div className="empleo-footer">
              <button className="btn-postulado" disabled>
                <FaCheck /> Postulado
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default MisPostulaciones;
