import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Asegúrate de que esto está correctamente importado
import './Login.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos de AOS

const Administrador = () => {
  const navigate = useNavigate(); // Inicializa useNavigate

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const adminStyle = {
    color: "white",
    textAlign: "center" 
  };

  return (
    <div className="administrador" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} data-aos="fade-up">
      <div className="admin" style={{ textAlign: 'center' }} data-aos="zoom-in">
        <img 
          src="/x1.png" 
          alt="Admin Logo" 
          style={{ width: '200px', height: '200px', marginBottom: '20px' }} 
          data-aos="fade-down"
        />
        <h1 style={adminStyle} data-aos="fade-right">BIENVENIDO AL ADMINISTRADOR</h1>
        <div className="btn-group-vertical" role="group" aria-label="Admin Menu" style={{ marginTop: '20px', display: 'inline-block' }}>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="100" onClick={() => navigate('/cargarempleo')}>
            CARGAR EMPLEO
          </button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="200">VER EMPLEOS</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="300">VER USUARIOS</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="400">GESTIONAR USUARIOS</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="500">GESTIONAR ADMINISTRADORES</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="600">SALIR</button>
        </div>
      </div>
    </div>
  );
}

export default Administrador;
