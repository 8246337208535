import React from 'react';
import MostrarEmpleos from './mostrarempleos';
import MenuVertical from './menuvertical';
import './inicio.css'; 

function Inicio() {
  return (
    <div className="App inicio-container">
      <MenuVertical />
      <MostrarEmpleos />
    </div>
  );
}

export default Inicio;
