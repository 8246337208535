import React, { useState } from 'react';
import './MenuVertical.css'; // Archivo CSS para estilos
import { FaUser, FaFileAlt, FaListAlt, FaSignOutAlt, FaBars } from 'react-icons/fa'; // Importamos íconos
import { useNavigate } from 'react-router-dom'; // Importa useNavigate para la navegación

const MenuVertical = () => {
  const [menuExpandido, setMenuExpandido] = useState(false);
  const navigate = useNavigate(); 

  const manejarClickMenu = () => {
    setMenuExpandido(!menuExpandido);
  };

  const handleMiPerfilClick = () => {
    navigate('/usuario'); // Navega a la ruta /usuario
  };

  const inicio = () => {
    navigate('/'); // Navega a la ruta /
  }

  return (
    <div className={`menu-vertical ${menuExpandido ? 'expandido' : ''}`}>
      <div className="menu-toggle" onClick={manejarClickMenu}>
        <FaBars className="menu-toggle-icon" />
      </div>
      {menuExpandido && (
        <ul className="menu-lista">
          <li className="menu-item" onClick={handleMiPerfilClick}>
            <FaUser className="menu-icon" />
            <span>Mi Perfil</span>
          </li>
          <li className="menu-item">
            <FaFileAlt className="menu-icon" />
            <span>Mi CV</span>
          </li>
          <li className="menu-item">
            <FaListAlt className="menu-icon" />
            <span>Mis Postulaciones</span>
          </li>
          <li className="menu-item" onClick={inicio}>
            <FaSignOutAlt className="menu-icon" />
            <span>Cerrar Sesión</span>
          </li>
        </ul>
      )}
    </div>
  );
};

export default MenuVertical;
