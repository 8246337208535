import React, { useState, useEffect } from "react";
import './Login.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Registro = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [codigo, setCodigo] = useState('');
  const [showCodigoForm, setShowCodigoForm] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 }); 
  }, []);

  const handleGuardar = () => {
    alert(`Registro exitoso para: ${email}`);
    setShowCodigoForm(true); 
  };

  const handleCodigoSubmit = () => {
    if (codigo.length === 6) {
      alert('Código aceptado. Registro completo.');
    } else {
      alert('El código debe tener 6 dígitos.');
    }
  };

  return (
    <div style={{height:"1000px"}}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

    <div className="registro" style={{ padding: '100px', maxWidth: '1000px', maxHeight: '3000px', margin: '0 auto', background: 'linear-gradient(135deg, #1a237e, #3949ab)', borderRadius: '10px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' }} data-aos="fade-up">
      {!showCodigoForm ? (
        <>
          <h2 style={{ color: 'white', textAlign: 'center' }}>Registro</h2>
          
          <div className="form-group">
            <label style={{ color: 'white' }}>Correo Electrónico de la Institución</label>
            <input 
              type="email" 
              className="form-control" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              placeholder="Correo Electrónico"
            />
          </div>

          <div className="form-group">
            <label style={{ color: 'white' }}>Contraseña</label>
            <input 
              type="password" 
              className="form-control" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Contraseña"
            />
          </div>

          <button className="btn btn-primary btn-block" onClick={handleGuardar}>
            Guardar
          </button>
        </>
      ) : (
        <>
          <h2 style={{ color: 'white', textAlign: 'center' }}>Ingrese el Código de Verificación</h2>
          
          <div className="form-group">
            <label style={{ color: 'white' }}>Código de 6 Dígitos</label>
            <input 
              type="text" 
              className="form-control" 
              value={codigo} 
              onChange={(e) => setCodigo(e.target.value)} 
              placeholder="Código de 6 Dígitos"
              maxLength="6"
            />
          </div>

          <button className="btn btn-primary btn-block" onClick={handleCodigoSubmit}>
            Verificar Código
          </button>
        </>
      )}
    </div>
    </div>
  );
}

export default Registro;
