import React, { useEffect } from "react";
import './Login.css'; 
import AOS from 'aos';
import 'aos/dist/aos.css'; // Importa los estilos de AOS

const Usuario = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Inicializa AOS con una duración de 1000ms para las animaciones
  }, []);

  const adminStyle = {
    color: "white",
    textAlign: "center" 
  };

  return (
    <div className="administrador" style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} data-aos="fade-up">
      <div className="admin" style={{ textAlign: 'center' }} data-aos="zoom-in">
        <img 
          src="/perfil.png" 
          alt="Admin Logo" 
          style={{ width: '300px', height: '300px', marginBottom: '20px' }} 
          data-aos="fade-down"
        />
        <h1 style={adminStyle} data-aos="fade-right">BIENVENIDO A TU PERFIL</h1>
        <div className="btn-group-vertical" role="group" aria-label="Admin Menu" style={{ marginTop: '20px', display: 'inline-block' }} data-aos="fade-left">
        <button type="button" className="btn btn-primary btn-register" data-aos="fade-up">MI CV</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up">CARGAR NUEVO CV</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="100">MODIFICAR DATOS</button>
          <button type="button" className="btn btn-primary btn-register" data-aos="fade-up" data-aos-delay="200">SALIR X</button>
        </div>
      </div>
    </div>
  );
}

export default Usuario;
